<template>
  <v-container class="mx-0 px-0" v-if="items && items.length > 0">
    <v-row class="mx-1 mb-1" v-if="title_hi && title">
      <div text>
        {{
          $store.getters.getCurrLocale == "hi"
            ? title_hi
              ? title_hi
              : title
            : title
        }}
      </div>
      <v-spacer></v-spacer>
      <v-icon class="animate__animated animate__lightSpeedInLeft"
        >mdi-arrow-right</v-icon
      >
      <v-icon></v-icon>
    </v-row>
    <v-row
      no-gutters
      :style="
        !$store.getters.isThemeDark
          ? 'border: 1px solid lightgrey; border-radius: 2px'
          : ''
      "
      class="justify-center mt-0 text-center align-content rounded-lg"
    >
      <v-col
        outlined
        cols="4"
        :md="items.length == 6 ? 4 : 4"
        v-for="item in items"
        :key="item.id"
      >
        <v-card
          tile
          :class="hasClass + ' ' + item.hasClass + ' rounded-lg'"
          height="130"
          style="overflow: hidden"
          elevation="0"
          @click="handleClick(item)"
        >
          <v-card-title class="justify-center">
            <v-img
              :contain="true"
              height="60"
              :src="item.banner_square ? item.banner_square : item.img"
              :style="
                isInverted
                  ? $store.getters['isThemeDark']
                    ? 'filter: invert(1)'
                    : 'filter: invert(0)'
                  : ''
              "
            >
              <v-badge
                v-if="item.hasBadge"
                :content="item.hasBadge"
                small
                :offset-x="-10"
                :offset-y="-3"
                :color="
                  item.badgeColor
                    ? item.badgeColor
                    : 'pink darken-2 white--text'
                "
              ></v-badge>
            </v-img>
            <div :class="'subtitle-1 ' + ''">
              {{
                $store.getters.getCurrLocale == "hi"
                  ? item.title_hi
                    ? item.title_hi
                    : item.title
                  : item.title
              }}
            </div>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "grid-three",
  props: ["title", "title_hi", "items", "hasClass", "isInverted"],
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    handleClick(item) {
      if (item.to) {
        item.to();
      } else {
        this.alertComingSoon();
      }
    },
    alertComingSoon() {
      this.$store.dispatch("setAlert", {
        message: "ComingSoon",
        icon: "mdi-alert",
        type: "orange accent-4 white--text",
        translate: true,
      });
    },
  },
};
</script>
<template>
  <v-layout style="width: 100%; background-color: rgba(0, 0, 0, 0)">
    <v-card
      outlined
      elevation="0"
      class="rounded-lg mb-2"
      style="width: 100%; background-color: rgba(0, 0, 0, 0)"
    >
      <v-responsive
        ref="bannerParent"
        :aspect-ratio="16 / 9"
        class="bannerParent"
      >
        <v-carousel
          @change="handleChange"
          ref="bannerSlider"
          hide-delimiters
          hide-delimiter-background
          show-arrows-on-hover
          cycle
          height="inherit"
          interval="6000"
          continuous
        >
          <v-carousel-item
            v-for="(item, i) in items"
            :key="i"
            :src="item.src"
            @click.stop="handleClick(item)"
            :title="getLangTitle(item)"
          >
            <div
              style="height: 100%; overflow: hidden"
              v-if="item.title || item.title_hi"
              class="d-flex"
            >
              <h4
                class="pa-2 heading align-self-end"
                style="
                  text-shadow: 1px 1px 2px black;
                  color: #fff;
                  background: #333;
                  width: 100%;
                "
              >
                {{ getLangTitle(item) }}
              </h4>
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-responsive>
    </v-card>
  </v-layout>
</template>

<script>
// import slide from "@wyhaya/vue-slide";
import { axios } from "@/plugins/axios";
import { Browser } from "@capacitor/browser";
import bus from "@/bus";

export default {
  name: "featured",
  data() {
    return {
      items: [],
      refFound: false,
    };
  },
  components: {
    // slide,
  },
  methods: {
    getLangTitle(item) {
      return this.isHindi
        ? item.title_hi
          ? item.title_hi
          : item.title
        : item.title;
    },
    handleClick(item) {
      if (!item.url && !item.uri) {
        return;
      }

      bus.$emit("showWait");
      if (item.url && item.url.length > 0) {
        Browser.open({ url: item.url });
      } else if (item.uri) {
        this.$router.push({ path: item.uri });
      }
      setTimeout(() => {
        bus.$emit("hideWait");
      }, 1000);
    },
    fetchBanners() {
      return axios
        .get("/globals/promotions/banners?page=1&ordery=featured|desc")
        .then((res) => {
          if (res.data.data) {
            const banners = res.data.data.filter((el) => el.featured);
            this.items.push(...banners);
          }
        });
    },
    fetchFeaturedNotes() {
      return axios
        .get("/prep/notes/public/?page=1&perPage=2&orderby=featured|desc")
        .then((res) => {
          if (!res || !res.data || !res.data.data || res.data.data.length < 1) {
            return;
          }
          let item = res.data.data[0];
          if (!item.featured) {
            return;
          }
          this.items.push({
            src: item.banner_square,
            uri: "/notes/" + item.id,
            title: item.title,
            title_hi: item.title_hi,
          });
        });
    },

    fetchFeaturedJobs() {
      return axios
        .get("/prep/jobs/public/?page=1&perPage=2&orderby=featured|desc")
        .then((res) => {
          if (!res || !res.data || !res.data.data || res.data.data.length < 1) {
            return;
          }
          let item = res.data.data[0];
          if (!item.featured) {
            return;
          }
          this.items.push({
            src: item.banner_square,
            uri: "/jobs/" + item.id,
            title: item.title,
            title_hi: item.title_hi,
          });
        });
    },
    fetchFeaturedVideo() {
      return axios
        .get("/videos/public/?page=1&perPage=2&orderby=featured|desc")
        .then((res) => {
          if (!res || !res.data || !res.data.data || res.data.data.length < 1) {
            return;
          }
          let item = res.data.data[0];
          if (!item.featured) {
            return;
          }
          this.items.push({
            src: item.thumbnail,
            uri: "/videos/" + item.id,
            title: item.title,
            title_hi: item.title_hi,
          });
        });
    },
    handleChange() {
      //this.handleBannerRef();
    },
    handleBannerRef() {
      const bannerParentRef = this.$refs.bannerParent;
      const bannerRef = this.$refs.bannerSlider;
      if (!bannerParentRef || !bannerRef) return;

      const parent = bannerParentRef.$el;
      const banner = bannerRef.$el;

      const width = parent.offsetWidth > 0 ? parent.offsetWidth : 1500;
      const height = width / 1.778;
      // console.log("size", width, height);

      banner.style.height = height;
      banner.style.width = width;
      banner.style.overflow = "hidden";

      parent.style.height = height;
      parent.height = height;

      let vImgs = banner.getElementsByClassName("v-image");
      let imgs = banner.getElementsByClassName("v-image__image");
      let containers = parent.getElementsByClassName("v-window__container");

      containers.forEach((itm) => {
        itm.style.height = height + "px";
        itm.style.width = width + "px";
      });

      vImgs.forEach((img) => {
        img.style.height = height + "px";
        img.style.width = width + "px";
      });

      imgs.forEach((img) => {
        img.style.height = height + "px";
        img.style.width = width + "px";
        img.style.backgroundPosition = "center center";
        img.style.objectFill = "cover";
      });
      if (bannerParentRef && bannerRef) {
        this.refFound = true;
      } else {
        this.refFound = false;
      }
    },
  },
  created() {
    this.fetchBanners();
    this.fetchFeaturedNotes();
    this.fetchFeaturedJobs();
    this.fetchFeaturedVideo();
  },

  mounted() {
    let intrvl = setInterval(() => {
      this.handleBannerRef();
      if (this.refFound) {
        clearInterval(intrvl);
      }
    }, 1000);

    window.onWindowResize = () => {
      this.handleBannerRef();
    };
  },
};
</script>
<style >
.bannerParent {
  aspect-ratio: 16 / 9;
  background: url("/img/icons/android-chrome-192x192.png") rgb(255, 255, 255);
  background-size: fill;
  object-fit: fill;
  background-position: center;
  background-repeat: no-repeat;
}
</style>

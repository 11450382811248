<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
      :fullscreen="isFullscreen"
      persistent
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="anim-shine indigo accent-4 white--text"
          x-large
          block
        >
          <v-icon left>mdi-question</v-icon>
          {{ $lang("QUICK_QUIZ") }}
        </v-btn>
      </template>

      <v-card>
        <v-toolbar>
          <v-toolbar-title> {{ $lang("QUICK_QUIZ") }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-chip
            outlined
            :color="this.score > 0 ? 'green white--text' : 'red white--text'"
            label
          >
            {{ $lang("score") }}:
            {{ score }}
          </v-chip>
          <v-toolbar-items>
            <v-btn icon @click="isFullscreen = !isFullscreen">
              <v-icon v-if="isFullscreen">mdi-fullscreen-exit</v-icon>
              <v-icon v-else>mdi-fullscreen</v-icon>
            </v-btn>

            <LocaleToggle />

            <v-btn icon @click="handleCancel">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
          <v-progress-linear
            :active="loading"
            color="green darken-2"
            :indeterminate="loading"
            absolute
            bottom
          ></v-progress-linear>
        </v-toolbar>
        <v-card-title> </v-card-title>
        <question-card-instant
          @selected="submitAnswer"
          :cardColor="
            $store.getters.isThemeDark
              ? ' darken-4 white--text'
              : ' lighten-4 black--text'
          "
          :useGlobalLocale="true"
          :hideTimer="true"
          :question="item"
          :idx="0"
          :currentQuestionId="item.id"
        />

        <v-card-actions>
          <v-btn
            :disabled="loading || parseInt(item.user_option) > 0"
            color="orange darken-3 white--text"
            @click="fetchItem"
          >
            {{ $lang("skip") }}
            <v-icon right>mdi-transit-skip</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading || !parseInt(item.user_option) > 0"
            color="primary white--text"
            @click="nextQuestion"
          >
            {{ $lang("next") + " " + $lang("question") }}
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
import QuestionCardInstant from "@/components/QuestionCardInstant";
import { scrollToTargetAdjusted } from "@/helpers/utils";
import LocaleToggle from "@/components/LocaleToggle";
export default {
  name: "quick-quiz",
  props: ["showDialog"],
  components: { QuestionCardInstant, LocaleToggle },

  data() {
    return {
      loading: false,
      dialog: this.showDialog,
      isFullscreen: this.$vuetify.breakpoint.smAndDown,
      item: {},
      score: 0,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
    isHindi() {
      return this.$store.getters.getCurrLocale == "hi";
    },
  },
  watch: {
    showDialog() {
      this.dialog = this.showDialog;
    },
    dialog() {
      if (this.dialog == false) {
        this.$emit("hideDialog");
      }
    },
    user(val) {
      if (val && val.id) {
        this.fetchItem();
      }
    },
  },
  methods: {
    handleCancel() {
      this.dialog = false;
      this.loading = false;
    },
    scrollToTargetAdjusted(elmId) {
      setTimeout(() => {
        let elm = document.getElementById(elmId);
        scrollToTargetAdjusted(elm, 60);
      }, 200);
    },
    submitAnswer() {
      if (this.item.user_option && parseInt(this.item.user_option) > -1) {
        if (parseInt(this.item.user_option) == this.item.correct_option) {
          this.score++;
        } else {
          this.score = this.score - 0.25;
        }
      }
    },
    nextQuestion() {
      // TODO: to be implemented
      this.fetchItem();
    },
    fetchItem() {
      if (!this.authUser || !this.authUser.id) {
        return;
      }
      this.loading = true;
      return axios
        .get(
          "/prep/questions/public/where/active/1/?page=1&perPage=1&randomize=true"
        )
        .then((res) => {
          if (res.data && res.data) {
            let itms = res.data.data;
            itms = itms.filter((el) => el.has_options && !el.language);
            if (itms && itms.length > 0) {
              this.item = itms[0];
            } else {
              return this.fetchItem();
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchItem();
  },
};
</script>

<style>
</style>

<template>
  <v-container v-if="item" class="mx-0 px-0">
    <v-card
      @click.stop="$router.push({ name: 'quote', params: { id: item.id } })"
      class="mx-auto mb-1 rounded-lg"
      elevation="0"
      outlined
      tile
    >
      <v-card-title>
        <v-icon large left>mdi-format-quote-open</v-icon>
        <span class="title font-weight-light">{{
          isHindi ? "आज का विचार" : "Quote of the Day"
        }}</span>
        <v-row
          v-if="item.count_shares && item.count_hearts"
          align="center"
          justify="end"
        >
          <v-icon class="mr-1"> mdi-heart </v-icon>
          <span class="subheading mr-2">{{ item.count_hearts }}</span>
          <span class="mr-1">·</span>
          <v-icon class="mr-1"> mdi-share-variant </v-icon>
          <span class="subheading">{{ item.count_shares }}</span>
        </v-row>
      </v-card-title>

      <v-card-text class="title font-weight-bold">
        <latex-html :html="isHindi ? item.quote_hi : item.quote" />
      </v-card-text>

      <v-card-actions>
        <v-list-item class="grow">
          <v-list-item-avatar v-if="item.author_image" color="grey darken-3">
            <v-img class="elevation-6" :src="item.author_image"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{ isHindi ? item.author_hi : item.author }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
// import bus from "@/bus";
import LatexHtml from "@/components/LatexHtml";
export default {
  name: "quote-of-the-day",
  components: { LatexHtml },

  data() {
    return {
      item: null,
    };
  },
  computed: {
    isHindi() {
      return this.$store.getters.getCurrLocale == "hi";
    },
    ...mapGetters({
      auth: "auth/auth",
    }),
  },
  methods: {},
  created() {
    // bus.$emit("showWait");
    axios
      .get("/quotes/random/daily")
      .then((res) => {
        if (res.data) {
          this.item = res.data;
        }
      })
      .finally(() => {
        // bus.$emit("hideWait");
      });
  },
};
</script>

<style>
</style>

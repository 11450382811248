<template>
  <v-row>
    <v-col cols="1">
      <v-icon :color="bgColor">{{
        bgColor == "green"
          ? "mdi-checkbox-marked-circle-outline"
          : "mdi-checkbox-blank-circle-outline"
      }}</v-icon>
    </v-col>
    <v-col cols="11">
      <latex-html
        :style="$store.getters.isThemeDark ? 'color: white' : 'color: black'"
        :html="html"
      />
    </v-col>
  </v-row>
</template>

<script>
import LatexHtml from "@/components/LatexHtml";
export default {
  props: ["optionId", "userOption", "html", "correctOption"],
  name: "icon-html",
  components: {
    LatexHtml,
  },
  data() {
    return {};
  },
  computed: {
    bgColor() {
      if (this.userOption) {
        if (this.optionId == this.correctOption) {
          return "green";
        } else {
          if (this.optionId == this.userOption) {
            return "red";
          }
        }
      }
      return "";
    },
  },
};
</script>

<style>
</style>
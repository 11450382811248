<template>
  <grid-three
    title="Daily Dose"
    title_hi="डेली डोज़"
    :items="items"
    :isInverted="true"
    hasClass="transparent"
  />
</template>

<script>
import { mapGetters } from "vuex";
import GridThree from "./GridThree";
// import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
  name: "daily-dose",
  props: ["title"],

  components: {
    GridThree,
    // LottieAnimation
  },
  data() {
    return {
      items: [
        {
          id: 1,
          title: "Learn",
          title_hi: "सीखिए",
          img: "/img/flaticons/coaching.png",
          // hasBadge: "new",
          // badgeColor: "blue darken-4 white--text",
          to: () => {
            this.$router.push({ name: "learn" });
          },
          hasClass: `
          animate__animated
          animate__flip
          animate__delay-3s
          `,
        },

        {
          id: 2,
          title: "Daily Tests",
          title_hi: "डेली टेस्ट्स",
          img: "/img/flaticons/online-test.png",
          to: () => {
            this.$router.push({ name: "exam", params: { id: 22 } });
          },
        },
        {
          id: 3,
          title: "Notes",
          title_hi: "नोट्स",
          img: "/img/flaticons/open-book.png",
          to: () => {
            this.$router.push({ name: "notes" });
          },
        },
        // {
        //   id: 4,
        //   title: "Doubts",
        //   title_hi: "संदेह",
        //   img: "/img/flaticons/confusion.png",
        //   to: () => {
        //     this.$router.push({ name: "doubts" });
        //   },
        //   hasBadge: "",
        //   badgeColor: "purple black--text",
        // },
        {
          id: 5,
          title: "Job Alert",
          title_hi: "जॉब अलर्ट",
          img: "/img/flaticons/job.png",
          to: () => {
            this.$router.push({ name: "jobs" });
          },
        },
        {
          id: 6,
          title: "One Liners",
          title_hi: "वन लाईनर्स",
          img: "/img/flaticons/number_1.png",
          to: () => {
            this.$router.push({ name: "tags" });
          },
        },
        // {
        //   id: 7,
        //   title: "My Profile",
        //   title_hi: "मेरी प्रोफाइल",
        //   img: "/img/flaticons/cv.png",
        //   to: () => {
        //     this.$router.push({ name: "profile" });
        //   },
        // },
        // {
        //   id: 7,
        //   title: "Downloads",
        //   title_hi: "डाउनलोड्स",
        //   img: "/img/flaticons/download-file.png",
        // },
        // {
        //   id: 8,
        //   title: "Feed",
        //   title_hi: "फ़ीड",
        //   img: "/img/flaticons/social-media.png",
        //   to: () => {
        //     this.$router.push({ name: "feed" });
        //   },
        //   // hasClass: "anim-shine",
        // },
        // {
        //   id: 6,
        //   title: "Curr. Affairs",
        //   title_hi: "सम सामयिकी",
        //   img: "/img/flaticons/current-affairs.png",
        //   hasBadge: "",
        //   badgeColor: "yellow black--text",
        //   to: () => {
        //     this.$router.push({ name: "exam", params: { id: 7 } });
        //   },
        // },

        {
          id: 9,
          title: "Videos",
          title_hi: "वीडियोज़",
          img: "/img/flaticons/video.png",
          // hasBadge: "new",
          // badgeColor: "blue accent-3 lighten-4 black--text",
          to: () => {
            this.$router.push({ name: "videos" });
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
  },
  methods: {
    setAnimController(evt) {
      console.log(evt);
    },
    alertComingSoon() {
      this.$store.dispatch("setAlert", {
        message: "ComingSoon",
        icon: "mdi-alert",
        type: "orange accent-4 white--text",
        translate: true,
      });
    },
  },
};
</script>
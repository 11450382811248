<template>
  <div>
    <v-card elevation="0" :class="'mb-1' + cardColor" tile flat>
      <question-card-timer
        v-if="!hideTimer"
        :question="question"
        :idx="idx"
        :currentQuestionId="currentQuestionId"
      />
      <v-card-title :class="'' + cardColor">
        <latex-html :isLatex="question.is_latex" :html="currLocale == 'en' ? question.title :  question.title_hi"  />
      </v-card-title>
    </v-card>
    <v-card elevation="0" :class="'' + cardColor" tile>
      <v-card-text
        :class="'' + cardColor"
        v-if="
          question && question.description && question.description.length > 8
        "
      >
        <latex-html :isLatex="question.is_latex" :html="currLocale == 'en' ? question.description :  question.description_hi" />
      </v-card-text>
      <v-card-text>
        <v-radio-group
          v-if="!question.user_option"
          v-model="question.user_option"
        >
          <radio-html
            :isDisabled="question.user_option ? true : false"
            value="1"
            :html="currLocale == 'en' ? question.option_a :  question.option_a_hi"
          />
          <radio-html
            :isDisabled="question.user_option ? true : false"
            value="2"
            :html="currLocale == 'en' ? question.option_b :  question.option_b_hi"
          />
          <radio-html
            :isDisabled="question.user_option ? true : false"
            value="3"
            :html="currLocale == 'en' ? question.option_c :  question.option_c_hi"
          />
          <radio-html
            :isDisabled="question.user_option ? true : false"
            value="4"
            :html="currLocale == 'en' ? question.option_d :  question.option_d_hi"
          />
        </v-radio-group>
        <div v-else>
          <icon-html
            :optionId="1"
            :userOption="question.user_option"
            :correctOption="question.correct_option"
            :html="currLocale == 'en' ? question.option_a :  question.option_a_hi"
          />
          <icon-html
            :optionId="2"
            :userOption="question.user_option"
            :correctOption="question.correct_option"
            :html="currLocale == 'en' ? question.option_b :  question.option_b_hi"
          />
          <icon-html
            :optionId="3"
            :userOption="question.user_option"
            :correctOption="question.correct_option"
            :html="currLocale == 'en' ? question.option_c :  question.option_c_hi"
          />
          <icon-html
            :optionId="4"
            :userOption="question.user_option"
            :correctOption="question.correct_option"
            :html="currLocale == 'en' ? question.option_d :  question.option_d_hi"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RadioHtml from "@/components/RadioHtml";
import LatexHtml from "@/components/LatexHtml";
import QuestionCardTimer from "@/components/QuestionCardTimer";
import IconHtml from "./IconHtml.vue";

export default {
  name: "instant-question-card",
  props: [
    "cardColor",
    "question",
    "idx",
    "currentQuestionId",
    "hideTimer",
    "useGlobalLocale",
  ],
  components: {
    RadioHtml,
    LatexHtml,
    QuestionCardTimer,
    IconHtml,
  },
  watch: {
    "question.user_option"(val) {
      if (val) {
        console.log("selected");
        this.$emit("selected", this.question.user_option);
      }
    },
  },
  computed: {
    currLocale() {
      if (this.useGlobalLocale) {
        return this.$store.getters.getCurrLocale;
      } else {
        return this.$store.getters["app/exams/selectedTestLanguage"];
      }
    },
  },
};
</script>

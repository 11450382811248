<template>
  <grid-three
    v-if="auth && auth.user && auth.user.id && items"
    hasClass="ma-1"
    title="My Exams"
    :items="items"
  />
</template>

<script>
import { mapGetters } from "vuex";
import GridThree from "./GridThree";
import { axios } from "@/plugins/axios";
// import bus from "@/bus";
export default {
  name: "my-exams",
  props: ["title"],

  components: { GridThree },
  data() {
    return {
      items: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
  },
  created() {
    this.fetchItems();
  },
  methods: {
    fetchItems() {
      this.loading = true;

      return axios
        .get("prep/exams/public/?page=1&orderby=featured|desc&perPage=6")
        .then((res) => {
          let data = res.data.data;

          // map route to, for when clicked
          data.map((el) => {
            el.to = () => {
              this.$router.push({ name: "exam", params: { id: el.id } });
            };
            return el;
          });

          this.items = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<template>
  <v-row>
    <v-col cols="12">
      <profile-bar />
      <!-- <DailyCheckInRewardDialog v-if="authUser && authUser.id" /> -->
    </v-col>
    <v-col cols="12" md="6">
      <featured />
      <daily-dose />
      <quick-quiz v-if="authUser && authUser.id" />
      <popular-exams class="mt-4" />
    </v-col>
    <v-col cols="12" md="6">
      <word-of-the-day class="mt-n3" />
      
      <v-btn
        class="my-3 orange darken-3 white--text"
        @click="
          () => {
            $router.push({ name: 'signs-practice' });
          }
        "
        x-large
        block
      >
        <!-- <v-icon left>mdi-cog</v-icon> -->
        {{ $lang("SIGNS_PRACTICE") }}
      </v-btn>
      <quote-of-the-day />

      <my-exams v-if="false" />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
import WordOfTheDay from "../WordOfTheDay";
import QuoteOfTheDay from "../QuoteOfTheDay.vue";
import DailyDose from "./DailyDose.vue";
import MyExams from "./MyExams";
import Featured from "./Featured";
import PopularExams from "./PopularExams";
import QuickQuiz from "./QuickQuiz.vue";
import ProfileBar from "@/app/profile/modules/ProfileBar";
// import DailyCheckInRewardDialog from "@/app/rewards/modules/DailyCheckInRewardDialog.vue";

export default {
  name: "home",
  components: {
    WordOfTheDay,
    QuoteOfTheDay,
    DailyDose,
    MyExams,
    PopularExams,
    QuickQuiz,
    ProfileBar,
    Featured,
    // DailyCheckInRewardDialog,
  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  created() {},
  methods: {},
};
</script>
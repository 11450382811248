<template>
  <v-container class="mx-0 px-0">
    <v-card
      v-if="item"
      class="mx-auto mb-1 rounded-lg pa-0"
      outlined
      elevation="0"
    >
      <v-card :id="getElementID" style="text-align: left" tile class="tile">
        <v-card-text>
          <div class="d-flex flex-row justify-space-between align-end">
            <div style="text-align: left">
              {{
                $store.getters.getCurrLocale == "hi"
                  ? "आज का शब्द"
                  : "Word of the Day"
              }}
            </div>
            <div style="width: min(100px, 25%)" @click="print">
              <v-img
                contain
                v-if="isDark"
                src="/app-logo/vikarn-white-green.png"
              />
              <v-img contain v-else src="/app-logo/vikarn-black-green.png" />
            </div>
          </div>
          <p class="display-1 text--primary py-0 my-0">{{ item.word }}</p>
          <p>{{ item.kind }}</p>
          <p
            class="py-0 my-0 pb-4 title"
            style="text-align: left"
            v-if="item.word_hi"
          >
            ({{ item.word_hi }})
          </p>
          <div class="text--primary">
            {{ item.summary }}
          </div>
        </v-card-text>
        <v-card-text>
          <div style="white-space: pre-line">{{ item.example }}</div>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="teal accent-4" @click="reveal = true">
            {{ $lang("Learn More") }}
          </v-btn>
        </v-card-actions>

        <v-expand-transition>
          <v-card
            v-if="reveal"
            class="transition-fast-in-fast-out v-card--reveal"
            style="height: 100%"
          >
            <v-card-text class="pb-0">
              <p class="display-1 text--primary">{{ item.detail_title }}</p>
              <p>
                {{ item.detail_text }}
              </p>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-btn text color="teal accent-4" @click="reveal = false">
                {{ $lang("CLOSE") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expand-transition>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
import { downloadDataURL } from "@/helpers/utils";
import { Device } from "@capacitor/device";

export default {
  name: "word-of-the-day",
  components: {},

  data() {
    return {
      reveal: false,
      item: null,
      info: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
    getElementID() {
      return "word-of-the-day";
    },
  },
  methods: {
    async print() {
      if (this.loading) return;
      this.$store.dispatch("setAlert", {
        message: "Please Wait...",
      });
      this.loading = true;
      const el = document.getElementById(this.getElementID);
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        type: "dataURL",
        useCORS: true,
        backgroundColor: null,
        allowTaint: true,
      };
      let output = await this.$html2canvas(el, options);
      // console.log("this.output", this.output);
      if (this.info && this.info.platform == "web") {
        downloadDataURL(output, "word-of-the-day");
      } else {
        this.openTab(output);
      }
      setTimeout(() => {
        this.loading = false;
      }, 1500);
    },
    openTab(data) {
      var image = new Image();
      image.src = data;

      var w = window.open("");
      w.document.write(image.outerHTML);
    },
    fetchItem() {
      return axios.get("/globals/word-of-the-day").then((res) => {
        this.item = res.data.data;
      });
    },
  },
  created() {
    this.fetchItem();
  },
  async mounted() {
    this.info = await Device.getInfo();
  },
};
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>

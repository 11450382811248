var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items && _vm.items.length > 0)?_c('v-container',{staticClass:"mx-0 px-0"},[(_vm.title_hi && _vm.title)?_c('v-row',{staticClass:"mx-1 mb-1"},[_c('div',{attrs:{"text":""}},[_vm._v(" "+_vm._s(_vm.$store.getters.getCurrLocale == "hi" ? _vm.title_hi ? _vm.title_hi : _vm.title : _vm.title)+" ")]),_c('v-spacer'),_c('v-icon',{staticClass:"animate__animated animate__lightSpeedInLeft"},[_vm._v("mdi-arrow-right")]),_c('v-icon')],1):_vm._e(),_c('v-row',{staticClass:"justify-center mt-0 text-center align-content rounded-lg",style:(!_vm.$store.getters.isThemeDark
        ? 'border: 1px solid lightgrey; border-radius: 2px'
        : ''),attrs:{"no-gutters":""}},_vm._l((_vm.items),function(item){return _c('v-col',{key:item.id,attrs:{"outlined":"","cols":"4","md":_vm.items.length == 6 ? 4 : 4}},[_c('v-card',{class:_vm.hasClass + ' ' + item.hasClass + ' rounded-lg',staticStyle:{"overflow":"hidden"},attrs:{"tile":"","height":"130","elevation":"0"},on:{"click":function($event){return _vm.handleClick(item)}}},[_c('v-card-title',{staticClass:"justify-center"},[_c('v-img',{style:(_vm.isInverted
                ? _vm.$store.getters['isThemeDark']
                  ? 'filter: invert(1)'
                  : 'filter: invert(0)'
                : ''),attrs:{"contain":true,"height":"60","src":item.banner_square ? item.banner_square : item.img}},[(item.hasBadge)?_c('v-badge',{attrs:{"content":item.hasBadge,"small":"","offset-x":-10,"offset-y":-3,"color":item.badgeColor
                  ? item.badgeColor
                  : 'pink darken-2 white--text'}}):_vm._e()],1),_c('div',{class:'subtitle-1 ' + ''},[_vm._v(" "+_vm._s(_vm.$store.getters.getCurrLocale == "hi" ? item.title_hi ? item.title_hi : item.title : item.title)+" ")])],1)],1)],1)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-btn icon @click.stop="handleClick()">
    <icon-translate />
  </v-btn>
</template>

<script>
import { setLocale } from "@/locales";
import IconTranslate from "./IconTranslate.vue"
export default {
  name: 'locale-toggle',
  components:{ IconTranslate },
  data() {
    return {};
  },
  methods: {
    handleClick() {
      if (this.$store.getters['getCurrLocale'] == "en") {
        setLocale("hi");
      } else {
        setLocale("en");
      }
    },
  },
};
</script>